.contacts {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	padding: 2.5rem 1.5rem 0;

	@media (min-width: bps.$L) {
		padding: 5rem 2.5rem 0;
	}

	@media (min-width: bps.$XL) {
		padding: 5rem 7.5rem 0;
	}

	.container {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		max-width: 80em;
		width: 100%;
	}
	.bg-triangle {
		width: 22.625rem;
		height: auto;
		position: absolute;
		top: 21.313rem;
		right: 0rem;
	}
	.bg-pic {
		width: 17rem;
		height: auto;
		position: absolute;
		top: 44.125rem;
		left: 0rem;
	}
	.bottom-border {
		width: 100%;
		position: absolute;
		top: 84rem;
	}
	h1 {
		margin-bottom: 1.5rem;
		max-width: 56rem;
	}
	p {
		max-width: 56rem;
		font-family: $archivo-stack;
		font-size: 1rem;
		letter-spacing: 0.0015em;
		color: colors.brand(gray, 600);

		@media (min-width: bps.$L) {
			font-size: 1.5rem;
		}
	}

	form {
		padding: 1.5rem;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		z-index: 1;
		max-width: 62.25rem;
		width: 100%;
		background: colors.brand(gray, 50);
		margin-top: 3rem;
		text-align: left;

		@media (min-width: bps.$L) {
			margin-top: 4.5rem;
			padding: 4.5rem 6rem;
		}

		.big-label {
			margin-bottom: 0.75rem;
			font-family: $archivo-stack;
			color: colors.brand(gray, 900);

			@media (min-width: bps.$L) {
				font-size: 1.25rem;
				margin-bottom: 1rem;
			}
		}
		.radio {
			margin-bottom: 1.5rem;
			width: 100%;

			@media (min-width: bps.$L) {
				margin-bottom: 3rem;
			}
		}
		.option {
			display: flex;
			flex-direction: row;
			margin-bottom: 0.75rem;
			gap: 0.75rem;

			label {
				width: 100%;
			}
		}
		input[type="radio"] {
			width: 1.5rem;
			height: 1.5rem;
			border: 1px solid colors.brand(gray, 900);
		}
		input[type="radio"]:checked {
			background-color: colors.brand(gray, 900);
			border: 6px solid;
		}
		input[type="text"] {
			width: 100%;
			height: 3rem;
			margin-bottom: 1.5rem;
			border: none;
			padding: 0.75rem 1rem;
			gap: 0.5rem;
			background: colors.brand(white, 0);
			color: colors.brand(gray, 600);
			font-size: 1.25rem;

			@media (min-width: bps.$L) {
				margin-bottom: 3rem;
			}
		}
		textarea {
			width: 100%;
			height: 9rem;
			margin-bottom: 1.5rem;
			border: none;
			padding: 0.75rem 1rem;
			gap: 0.5rem;
			background: colors.brand(white, 0);
			color: colors.brand(gray, 600);
			resize: none;
			font-size: 1.25rem;

			@media (min-width: bps.$L) {
				margin-bottom: 3rem;
			}
		}
	}
}

.help {
	justify-content: center;
	display: flex;
	padding: 9rem 1.5rem 1.5rem 1.5rem;
	margin-top: -4.5rem;
	background: colors.brand(gray, 900);

	@media (min-width: bps.$L) {
		padding: 15rem 2.5rem 7.5rem 2.5rem;
	}

	@media (min-width: bps.$XL) {
		padding: 15rem 7.5rem 7.5rem 7.5rem;
	}

	.container {
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: 80em;
		width: 100%;
	}
	.caption {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		z-index: 2;

		.titles {
			display: flex;
			flex-direction: column;
			column-gap: 1.5rem;
			margin-bottom: 3rem;

			@media (min-width: bps.$L) {
				flex-direction: row;
				margin-bottom: 5.5rem;
			}

			h2 {
				color: colors.brand(white, 0);
				width: 100%;
				margin-bottom: 1.5rem;

				@media (min-width: bps.$L) {
					flex-direction: row;
					margin-bottom: 0;
				}
			}

			p {
				font-size: 16px;
				color: colors.brand(gray, 300);
				width: 100%;
			}
		}
	}
	.cards {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		width: 100%;
		gap: 1.5rem;
		z-index: 1;

		@media (min-width: bps.$L) {
			flex-direction: row;
		}

		.card {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			flex: 0 1 auto;
			width: 100%;
			padding: 2.5rem 2rem;
			background: colors.brand(gray, 800);

			h4 {
				color: colors.brand(white, 0);
			}
			> img {
				height: 6.5rem;
				align-self: flex-start;
				margin-top: 1.5rem;
				margin-bottom: 1.5rem;
			}
			p {
				flex-grow: 1;
				padding-bottom: 1.5rem;
				text-align: start;
				font-size: 16px;
				color: colors.brand(gray, 300);
			}
			a {
				border-bottom-color: colors.brand(gray, 700);
			}
		}
	}
}
